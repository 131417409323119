@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";


.button-link-btn {
    display: flex;
    flex-wrap: wrap;
    padding-left: 10px;
    padding-right: 10px;
}

.button-link-text {
    /* stylelint-disable-next-line */
    -webkit-line-clamp: 2;
    /* stylelint-disable-next-line */
    -webkit-box-orient: vertical;
    /* stylelint-disable-next-line */
    display: -webkit-box;
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
}

/* stylelint-disable-next-line */
.experience-buttonLinks {
    line-height: 50px;
}
